import { Trans, useTranslation } from 'react-i18next';

import type { TextFieldProps } from '~/components/form';
import { TextField } from '~/components/form';

export const Username = ({
  name = 'userName',
  type = 'text',
  autoComplete = 'username',
  label,
  ...props
}: Partial<TextFieldProps>) => {
  const { t } = useTranslation('field');
  return (
    <TextField
      name={name}
      label={
        label ?? (
          <Trans ns='field' i18nKey='username' t={t}>
            Username
          </Trans>
        )
      }
      type={type}
      // icon={<FaUser/>}
      autoComplete={autoComplete}
      {...props}
    />
  );
};
